<template>
    <v-dialog v-model="internalOpen" max-width="600px" persistent>
        <v-card style="background-color: #FFFFFF; position: relative;">
            <v-card-title class="px-0 py-0 d-block" style="position: sticky; top: 0; background-color: inherit; z-index: 1;">
                <div class="d-flex justify-space-between align-center px-4 py-4">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="px-4 py-4"
                        rounded
                        text
                        @click.stop="cerrarModal"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                    <v-tooltip v-if="bitacora" left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip 
                                v-bind="attrs"
                                v-on="on" 
                                label 
                                class="font-weight-bold float-right"
                            >
                                {{ bitacora.tipo.nombre }}
                            </v-chip>
                        </template>
                        <span>{{ bitacora.tipo.descripcion }}</span>
                    </v-tooltip>
                </div>
                <v-divider />
            </v-card-title>
            <v-card-text class="px-4 py-4">
                <dl v-if="bitacora">
                    <dt class="font-weight-bold">Fecha creación:</dt>
                    <dd>{{ bitacora.fecha }}</dd>
                    
                    <template>
                        <dt class="font-weight-bold mt-4">Departamento:</dt>
                        <dd>{{ bitacora.departamento.nombre }}</dd>
                    </template>
                    
                    <template v-if="bitacora.creadoPorFormulario">
                        <dt class="font-weight-bold mt-4">Creado por:</dt>
                        <dd>{{ bitacora.creadoPor }}</dd>
                    </template>

                    <template v-if="bitacora.baseLegal">
                        <dt class="font-weight-bold mt-4">Base legal:</dt>
                        <dd>{{ bitacora.baseLegal }}</dd>
                    </template>

                    <dt class="font-weight-bold mt-4">Descripción:</dt>
                    <dd>{{ bitacora.descripcion }}</dd>
                </dl>

                <v-btn @click.stop="cerrarModal" class="d-block mx-auto mt-8" color="primary" large>Cerrar</v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { isNil } from 'lodash';

export default {
    name: 'ModalVerBitacora',
    emits: ['on-visibility-change'],
    props: {
        isOpen: { type: Boolean },
        bitacora: { type: Object },
    },
    data: () => ({
        internalOpen: false,
    }),
    methods: {
        cerrarModal() {
            this.internalOpen = false;
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },    
}
</script>