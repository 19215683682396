<template>
    <Fragment>
        <v-dialog v-model="internalOpen" max-width="800px" persistent>
            <v-card style="background-color: #FFFFFF; position: relative;">
                <v-card-title class="px-0 py-0 d-block" style="position: sticky; top: 0; background-color: inherit; z-index: 1;">
                    <div class="d-flex justify-space-between align-center px-4 py-4">
                        <v-btn 
                            style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                            class="px-4 py-4"
                            rounded
                            text
                            @click.stop="cerrarModal"
                        >
                            <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                    </div>
                    <v-divider />
                </v-card-title>
                <v-card-text class="px-4 py-4">
                    <v-select
                        v-model="tipoRegistro"
                        :items="tipos.data"
                        :loading="tipos.isLoading"
                        :disabled="creacionRegistro.isLoading"
                        label="Tipo de registro"
                        placeholder="Seleccione el tipo de registro"
                        class="mb-4"
                        item-text="nombre"
                        item-value="id"
                        return-object
                        hide-details
                        outlined
                    />
                    <v-form v-if="tipoRegistro" ref="form" @submit.prevent>
                        <v-textarea
                            v-if="tipoRegistro.tiene_base_legal"
                            v-model="form.base_legal"
                            label="Base legal"
                            placeholder="Ingrese la base legal"
                            outlined
                            rows="4"
                            no-resize
                            :error-messages="baseLegalErrors"
                            :disabled="creacionRegistro.isLoading"
                            @change="$v.form.base_legal.$touch()"
                            @blur="$v.form.base_legal.$touch()"
                        />
                        <v-textarea
                            label="Descripción"
                            v-model="form.descripcion"
                            placeholder="Ingrese la descripción"
                            outlined
                            rows="4"
                            no-resize
                            :error-messages="descripcionErrors"
                            :disabled="creacionRegistro.isLoading"
                            @change="$v.form.descripcion.$touch()"
                            @blur="$v.form.descripcion.$touch()"
                        />
                        <v-file-input
                            v-model="form.adjunto"
                            label="Adjunto"
                            placeholder="Seleccione un PDF"
                            outlined
                            light
                            accept="application/pdf"
                            :disabled="creacionRegistro.isLoading"
                        />
                        <div class="d-flex justify-center flex-wrap flex-md-nowrap" style="gap:16px">
                            <v-btn 
                                color="secondary" 
                                class="flex-grow-1 flex-shrink-1"
                                :disabled="creacionRegistro.isLoading"
                                large
                                @click.stop="cerrarModal"
                            >
                                Cerrar
                            </v-btn>
                            <v-btn 
                                color="primary" 
                                class="flex-grow-1 flex-shrink-1"
                                type="submit"
                                large
                                :loading="creacionRegistro.isLoading"
                                @click.stop="enviarFormulario"
                            >
                                Guardar
                            </v-btn>
                        </div>
                    </v-form>
                    <v-btn 
                        v-else
                        large
                        color="primary" 
                        class="d-block mx-auto"
                        @click.stop="cerrarModal"
                    >
                        Cerrar
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ConfirmationModalComponent
            :is-open="modalConfirmacionAbierta"
            description="¿Desea continuar con la creación del registro?"
            :is-loading="creacionRegistro.isLoading"
            @confirm="crearRegistro"
            @cancel="modalConfirmacionAbierta = false"
        />
    </Fragment>
</template>
<script>
import { isNil } from 'lodash';
import { Validator } from '@/utils/form-validation';
import { required } from 'vuelidate/lib/validators';
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { convertToFormData, removePropsFromObjectIfNil } from '@/utils/data';
import { Fragment } from 'vue-fragment';
import { ConfirmationModalComponent } from '@/components/utils';

const dataInicialForm = {
    descripcion: null,
    base_legal: null,
    adjunto: null,
};

export default {
    name: 'ModalCreacionRegistro',
    emits: ['on-visibility-change', 'on-nueva-actividad', 'on-ocultamiento-seguimiento'],
    validations() {
        return ({
            form: {
                descripcion: { required },
                ...this.tipoRegistro?.tiene_base_legal && ({ base_legal: { required }, }),
                adjunto: {},
            }
        });
    },
    props: {
        idSeguimiento: { type: String },
        isOpen: { type: Boolean },
        tipos: { type: Object },
    },
    data: () => ({
        internalOpen: false,
        tipoRegistro: null,
        form: dataInicialForm,
        formCache: {},
        creacionRegistro: createLoadable(null),
        modalConfirmacionAbierta: false,
    }),
    computed: {
        descripcionErrors() {
            return new Validator(this.$v.form.descripcion).detect().getResult();
        },
        baseLegalErrors() {
            return this.$v.form.base_legal ? (new Validator(this.$v.form.base_legal).detect().getResult()) : [];
        },
    },
    methods: {
        limpiarFormulario() {
            this.form = dataInicialForm;
            this.$v.form.$reset();
            this.$refs.form?.reset();
            this.$v.form.adjunto.$reset();
        },
        cerrarModal() {
            this.internalOpen = false;
            this.tipoRegistro = null;
            this.limpiarFormulario();
        },
        enviarFormulario() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.confirmarCreacionRegistro();
        },
        confirmarCreacionRegistro() {
            this.formCache = removePropsFromObjectIfNil({ ...this.form, id_tipo: this.tipoRegistro?.id }, ['adjunto', 'base_legal']);
            this.cerrarModal();
            this.modalConfirmacionAbierta = true;
        },
        async crearRegistro() {
            toggleLoadable(this.creacionRegistro);
            const dataToConvert = this.formCache;
            const formData = convertToFormData(dataToConvert);
            const { data } = await this.services.SeguimientoProcesos.crearBitacora(this.idSeguimiento, formData);
            this.modalConfirmacionAbierta = false;
            setLoadableResponse(this.creacionRegistro, data, { 
                showAlertOnSuccess: true, 
                skipOnSuccess: true, 
                dontShowErrorIfCode: ['SEGUIMIENTO_NO_DISPONIBLE', 'SEGUIMIENTO_OCULTO_PARA_USUARIO'],
            });

            if (isResponseSuccesful(data)) {
                this.cerrarModal();
                this.$emit('on-nueva-actividad');

                return;
            }

            if (['SEGUIMIENTO_NO_DISPONIBLE', 'SEGUIMIENTO_OCULTO_PARA_USUARIO'].includes(data.error.code)) this.$emit('on-ocultamiento-seguimiento');
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value))
                return;
            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
        tipoRegistro() {
            this.limpiarFormulario();
        },
    },
    components: { Fragment, ConfirmationModalComponent }
}
</script>