<template>
    <Fragment>
        <v-select
            v-model="estado"
            :items="estadoItems"
            :loading="estados.isLoading || cambioEstado.isLoading"
            label="Estado"
            placeholder="Seleccione un estado"
            style="flex-basis: 220px"
            class="flex-grow-1 flex-shrink-1 align-self-end"
            item-text="nombre"
            item-value="id"
            hide-details
            outlined
            @change="confirmarCambioEstado"
        />
        <ConfirmationModalComponent
            :is-open="modalConfirmacionAbierta"
            description="¿Desea continuar con el cambio de estado?"
            :is-loading="cambioEstado.isLoading"
            @confirm="cambiarEstado"
            @cancel="cancelarCambio"
        />
    </Fragment>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { ConfirmationModalComponent } from '@/components/utils';
import { Fragment } from 'vue-fragment';
import { mapState } from 'vuex';

export default {
    name: 'CambioEstado',
    components: { Fragment, ConfirmationModalComponent },
    emits: ['on-cambio-estado', 'on-ocultamiento-seguimiento'],
    props: {
        idSeguimiento: {  type: String },
        estadoActual: { type: Number },
    },
    data: () => ({
        estado: null,
        estadoPrevio: null,
        modalConfirmacionAbierta: false,
        cambioEstado: createLoadable(null),
    }),
    computed: {
        ...mapState("seguimientoProcesos", ["estados"]),
        estadoItems() {
            return this.estados.data.filter((estado) => estado.id !== 1);
        },
    },
    methods: {
        confirmarCambioEstado() {
            this.modalConfirmacionAbierta = true;
        },
        async cambiarEstado() {
            toggleLoadable(this.cambioEstado);
            const { data } = await this.services.SeguimientoProcesos.cambiarEstado(this.idSeguimiento, this.estado);
            this.modalConfirmacionAbierta = false;
            setLoadableResponse(this.cambioEstado, data, { showAlertOnSuccess: true, dontShowErrorIfCode: ['SEGUIMIENTO_NO_DISPONIBLE', 'SEGUIMIENTO_OCULTO_PARA_USUARIO'] });

            if (isResponseSuccesful(data)) {
                this.$emit('on-cambio-estado');
                return;
            } 

            if (['SEGUIMIENTO_NO_DISPONIBLE', 'SEGUIMIENTO_OCULTO_PARA_USUARIO'].includes(data.error.code)) this.$emit('on-ocultamiento-seguimiento');
        },
        cancelarCambio() {
            this.estado = this.estadoPrevio;
            this.modalConfirmacionAbierta = false;
        },
    },
    watch: {
        estadoActual: {
            handler(value) {
                this.estado = value;
            },
            immediate: true,
        },
        estado(value, previous) {
            if (previous === this.estadoPrevio) return;

            this.estadoPrevio = previous;
        }
    },
}
</script>