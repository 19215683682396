<template>
    <Fragment>
        <v-dialog v-model="internalOpen" max-width="800px" persistent>
            <v-card style="background-color: #FFFFFF; position: relative;">
                <v-card-title class="px-0 py-0 d-block" style="position: sticky; top: 0; background-color: inherit; z-index: 1;">
                    <div class="d-flex justify-space-between align-center px-4 py-4">
                        <v-btn 
                            style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                            class="px-4 py-4"
                            rounded
                            text
                            @click.stop="cerrarModal" 
                            :disabled="guardadoSeguimiento.isLoading"
                        >
                            <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                    </div>
                    <v-divider />
                </v-card-title>
                <v-card-text class="px-4 py-4">
                    <RetryDataLoading 
                        :loadable="departamento"
                        :retryHandler="cargarDepartamentoUsuarioLogueado"
                    >
                        <p v-if="departamento.isLoading">Cargando departamento de usuario...</p>
                        <v-form @submit.prevent>
                            <v-select
                                v-model="responsable"
                                :items="responsables.data"
                                :loading="responsables.isLoading"
                                label="Responsable"
                                item-text="nombre"
                                item-value="id"
                                placeholder="Seleccione un responsable"
                                :disabled="guardadoSeguimiento.isLoading || seguimientoRevisado"
                                hide-details
                                clearable
                                outlined
                            />
                            <v-select
                                v-model="asignado"
                                :items="tecnicos.data"
                                :loading="tecnicos.isLoading"
                                label="Asignado"
                                item-text="nombre"
                                item-value="id"
                                placeholder="Seleccione un asignado"
                                class="mt-4"
                                :disabled="guardadoSeguimiento.isLoading || seguimientoRevisado"
                                hide-details
                                clearable
                                outlined
                            />
                            <v-alert v-if="seguimientoRevisado" type="info" class="mt-4" dense>
                                El seguimiento del proceso se encuentra marcado como revisado.
                            </v-alert>
                            <div class="d-flex justify-center flex-wrap flex-md-nowrap mt-4" style="gap:16px">
                                <v-btn 
                                    color="secondary" 
                                    class="flex-grow-1 flex-shrink-1"
                                    large
                                    :disabled="guardadoSeguimiento.isLoading"
                                    @click.stop="cerrarModal"
                                >
                                    Cerrar
                                </v-btn>
                                <v-btn 
                                    v-if="!seguimientoRevisado"
                                    color="primary" 
                                    class="flex-grow-1 flex-shrink-1"
                                    large
                                    type="submit"
                                    :loading="guardadoSeguimiento.isLoading"
                                    @click.stop="confirmarGuardadoSeguimiento"
                                >
                                    Guardar
                                </v-btn>
                            </div>
                        </v-form>
                    </RetryDataLoading>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ConfirmationModalComponent
            :is-open="modalConfirmacionAbierta"
            description="¿Desea continuar con la asignación?"
            :is-loading="guardadoSeguimiento.isLoading"
            @confirm="guardarSeguimiento"
            @cancel="modalConfirmacionAbierta = false"
        />
    </Fragment>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import { RetryDataLoading, ConfirmationModalComponent } from '@/components/utils';
import { mapState, mapActions } from "vuex";
import { isNil } from 'lodash';
import { Fragment } from 'vue-fragment';

export default {
    name: 'ModalAsignarSeguimiento',
    emits: ['on-visibility-change', 'on-cambio-asignacion'],
    components: { RetryDataLoading, Fragment, ConfirmationModalComponent },
    props: {
        isOpen: { type: Boolean },
        idProceso: { type: Number },
        asignacionActual: { type: Object },

    },
    data: () => ({
        internalOpen: false,
        modalConfirmacionAbierta: false,
        responsables: createLoadable([]),
        tecnicos: createLoadable([]),
        guardadoSeguimiento: createLoadable(null),
        formCache: {},
        // Formulario
        responsable: null,
        asignado: null,
    }),
    computed: {
        ...mapState("seguimientoProcesos", ["departamento"]),
        departamentoUsuario() {
            return this.departamento.data?.id;
        },
        departamentoProceso() {
            return this.asignacionActual.departamento;
        },
        asignacionEnMismoDepto() {
            return (
                !isNil(this.departamentoUsuario) && 
                !isNil(this.departamentoProceso) && 
                this.departamentoUsuario === this.departamentoProceso
            );
        },
        asignacionDisponible() {
            return (
                (this.proceso && !this.departamentoProceso) ||
                this.asignacionEnMismoDepto
            );
        },
        seguimientoRevisado() {
            return this.asignacionActual.estado === 4;
        },
    },
    methods: {
        ...mapActions("seguimientoProcesos", ["cargarDepartamentoUsuarioLogueado"]),
        limpiarFormulario() {
            this.responsable = null;
            this.asignado = null;
        },
        cerrarModal() {
            this.internalOpen = false;
            this.limpiarFormulario();
        },
        confirmarGuardadoSeguimiento() {
            // if (
            //     !isNil(this.responsable) &&
            //     !isNil(this.asignado) &&
            //     this.responsable === this.asignado
            // ) {
            //     this.pushAppMessage({ type: 'error', message: 'Responsable y asignado no pueden ser la misma persona.' });
            //     return;
            // }

            this.formCache = { responsable: this.responsable, asignado_a: this.asignado };
            this.cerrarModal();
            this.modalConfirmacionAbierta = true;
        },

        async guardarSeguimiento() {
            toggleLoadable(this.guardadoSeguimiento);
            const { data } = await this.services.SeguimientoProcesos.guardarSeguimiento(this.idProceso, this.formCache);
            this.modalConfirmacionAbierta = false;
            setLoadableResponse(this.guardadoSeguimiento, data, { 
                showAlertOnSuccess: true, 
                skipOnSuccess: true, 
                dontShowErrorIfCode: ['SEGUIMIENTO_NO_DISPONIBLE', 'SEGUIMIENTO_OCULTO_PARA_USUARIO'],
            });

            if (isResponseSuccesful(data)) {
                this.$emit('on-cambio-asignacion');
                return;
            };

            if (['SEGUIMIENTO_NO_DISPONIBLE', 'SEGUIMIENTO_OCULTO_PARA_USUARIO'].includes(data.error.code)) this.$emit('on-ocultamiento-seguimiento');
        },
        // Lista de usuarios
        async cargarResponsables() {
            toggleLoadable(this.responsables);
            const { data, headers } = await this.services.SeguimientoProcesos.obtenerTecnicosDepartamento();
            setLoadableResponse(this.responsables, data, headers);
        },
        async cargarTecnicos() {
            toggleLoadable(this.tecnicos);
            const { data, headers } = await this.services.SeguimientoProcesos.obtenerTecnicosDepartamento();
            setLoadableResponse(this.tecnicos, data, headers);
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
        asignacionActual: {
            handler(value) {
                this.responsable = value?.responsable;
                this.asignado = value?.asignado;
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.cargarResponsables();
        this.cargarTecnicos();
    },
};
</script>